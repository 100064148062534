import { Button, capitalize, Grid } from '@mui/material'
import React, { useState } from 'react'
import DataTable from '../../_components/_dataTable/DataTable'
import LoadingScreen from '../../_components/LoadingScreen'
import DefaultAxios from '../../_utils/DefaultAxios'
import { downloadLink, generalErrorHandler, renderToastSuccess } from '../../_utils/Helper'
import AccountTrackerDetail from './_components/AccountTrackerDetail'

type Props = {}

export interface IState {
    id: number
    datetime: string
    account_name: string
    accont_email: string
}

const AccountTracker = (props: Props) => {
    const url = `${process.env.REACT_APP_API_URL}/account-tracking-visit`
    const [selectedData, setSelectedData] = useState<IState | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSeeDetail = (rows: IState) => {
        setSelectedData(rows)
    }

    const columns = [
        {
            name: 'datetime',
            label: 'First Login',
            type: 'date',
            filterable: true
        },
        {
            name: 'account_name',
            label: 'Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'account_email',
            label: 'Email',
            type: 'string',
            filterable: true
        },
        {
            name: 'role_type',
            label: 'Role',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: 'user', value: 'User' },
                { key: 'agent', value: 'Agent' },
            ],
            render: (data: any) => {
                return capitalize(data)
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            filterable: false,
            render: (rows: any) => (
                <Button variant='contained' onClick={() => handleSeeDetail(rows)}>
                    Detail
                </Button>
            )
        }
    ]

    const handleExport = () => {
        setIsLoading(true)
        DefaultAxios.get(`${url}/export`, {
            responseType: 'blob'
        })
            .then(res => res.data)
            .then(res => {
                downloadLink(window.URL.createObjectURL(new Blob([res])), `account-tracker-list.xlsx`)
                renderToastSuccess('Export Berhasil!')
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button variant='contained' sx={{ float: 'right' }} onClick={handleExport}>
                        Export
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        url={url}
                    />
                </Grid>
            </Grid>

            {
                selectedData &&
                <AccountTrackerDetail
                    open={selectedData !== null}
                    onClose={() => {
                        setSelectedData(null)
                    }}
                    data={selectedData}
                />
            }
        </>
    )
}

export default AccountTracker
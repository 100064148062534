import React, { useMemo, useState } from 'react'

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable'
import LoadingScreen from '../../_components/LoadingScreen'
import GeneralFormModal, { GeneralFileType } from '../../_components/_modals/GeneralFormModal'
import { Button, Grid, IconButton, Tooltip } from '@mui/material'

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess, renderWarningButton } from '../../_utils/Helper'

/**
 * Icons
 */
import { Delete, Edit } from '@mui/icons-material'

interface PilarProjectListProps {

}

type FormState = {
    id?: string
    project_id: string
    bracket_price: string
    name: string
    desktop_image: {
        file: null | File
        url: string
    }
    mobile_image: {
        file: null | File
        url: string
    }
}

const PilarProjectList = (props: PilarProjectListProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [reDrawDatatable, setReDrawDatatable] = useState(0)

    const [selectedData, setSelectedData] = useState<null | FormState>(null)

    const columns = useMemo(() => ([
        {
            name: 'name',
            label: 'Name',
            type: 'string',
            filterable: true
        },
        {
            name: 'project_id',
            label: 'Project',
            type: 'string',
            filterable: true,
            render: (data: any) => PROJECT_LIST.find(project => project.value === data.toString())?.label || '-',
        },
        {
            name: 'bracket_price',
            label: 'Bracket Price',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...BRACKET_PRICE_LIST.map(bracket => ({
                    key: bracket.value,
                    value: bracket.label,
                })),
            ],
            filterable: true,
            render: (data: any) => BRACKET_PRICE_LIST.find(bracket => bracket.value === data.toString())?.label || '-',
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                onClick={() => handleEdit(row)}
                            >
                                <Edit fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                color="primary"
                                onClick={() => handleDelete(row.id)}
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            }
        },
    ]), [])

    const handleEdit = (row: any) => {
        setSelectedData({
            ...row,
            desktop_image: {
                file: null,
                url: row.desktop_image_url,
            },
            mobile_image: {
                file: null,
                url: row.mobile_image_url,
            },
        })
        setIsModalOpen(true)
    }

    const handleDelete = (id: number) => {
        renderWarningButton('Apakah anda yakin ingin menghapus project ini?')
            .then(res => res.value)
            .then(value => {
                if (value) {
                    setIsLoading(true)

                    DefaultAxios.delete(`${process.env.REACT_APP_API_URL}/pilar-listing/${id}`)
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess('Berhasil menghapus project')
                            setReDrawDatatable(prev => prev + 1)
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })
                }
            })
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <GeneralFormModal
                title={selectedData === null ? 'Add' : 'Edit'}
                open={isModalOpen}
                forms={[
                    {
                        name: 'project_id',
                        label: 'Project',
                        defaultValue: selectedData?.project_id || '',
                        type: 'select',
                        options: [
                            {
                                value: '',
                                label: 'Choose Project'
                            },
                            ...PROJECT_LIST,
                        ],
                        alias: 'Project',
                        required: true,
                    },
                    {
                        name: 'bracket_price',
                        label: 'Bracket Price',
                        defaultValue: selectedData?.bracket_price || '',
                        type: 'select',
                        options: [
                            {
                                value: '',
                                label: 'Choose Bracket Price'
                            },
                            ...BRACKET_PRICE_LIST,
                        ],
                        alias: 'Bracket Price',
                        required: true,
                    },
                    {
                        name: 'name',
                        label: 'Name',
                        defaultValue: selectedData?.name || '',
                        type: 'input',
                        alias: 'Name',
                        required: true,
                    },
                    {
                        name: 'desktop_image',
                        label: 'Desktop Image',
                        defaultValue: {
                            file: null,
                            url: selectedData?.desktop_image.url || '',
                        },
                        type: 'image',
                        alias: 'Desktop Image',
                        required: true,
                        customValidation: value => {
                            if ((value as GeneralFileType).file === null && (value as GeneralFileType).url === '') {
                                return {
                                    isValid: false,
                                    errorMessage: 'Harap menambahkan Desktop Image'
                                }
                            }

                            return {
                                isValid: true,
                                errorMessage: '',
                            }
                        }
                    },
                    {
                        name: 'mobile_image',
                        label: 'Mobile Image',
                        defaultValue: {
                            file: null,
                            url: selectedData?.mobile_image.url || '',
                        },
                        type: 'image',
                        alias: 'Mobile Image',
                        required: true,
                        skipCompress: true,
                        customValidation: value => {
                            if ((value as GeneralFileType).file === null && (value as GeneralFileType).url === '') {
                                return {
                                    isValid: false,
                                    errorMessage: 'Harap menambahkan Mobile Image'
                                }
                            }

                            return {
                                isValid: true,
                                errorMessage: '',
                            }
                        }
                    },
                ]}
                onClose={() => {
                    setIsModalOpen(false)
                    setSelectedData(null)
                }}
                onSubmit={(state, onFinal) => {
                    const fd = new FormData()

                    fd.append('name', state.name as string)
                    fd.append('project_id', state.project_id as string)
                    fd.append('bracket_price', state.bracket_price as string)

                    if ((state.desktop_image as GeneralFileType).file) {
                        fd.append('desktop_image', (state.desktop_image as GeneralFileType).file!)
                    }

                    if ((state.mobile_image as GeneralFileType).file) {
                        fd.append('mobile_image', (state.mobile_image as GeneralFileType).file!)
                    }

                    if (selectedData) {
                        fd.append('_method', 'PATCH')
                    }

                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/pilar-listing${selectedData ? `/${selectedData?.id}` : ''}`, fd)
                        .then(res => res.data)
                        .then(data => {
                            renderToastSuccess(`Berhasil ${selectedData ? 'mengubah' : 'menambahkan'} data`)
                            setReDrawDatatable(prev => prev + 1)
                            setIsModalOpen(false)
                            setSelectedData(null)
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            onFinal()
                        })
                }}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        onClick={() => setIsModalOpen(true)}
                        sx={{ mb: 2 }}
                    >
                        Add Project
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        url={`${process.env.REACT_APP_API_URL}/pilar-listing`}
                        columns={columns}
                        reDraw={reDrawDatatable}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const PROJECT_LIST = [
    {
        value: '1',
        label: 'BSD City'
    },
    {
        value: '2',
        label: 'Tangerang'
    },
    {
        value: '3',
        label: 'Jakarta'
    },
    {
        value: '4',
        label: 'Bogor'
    },
    {
        value: '5',
        label: 'Bekasi'
    },
    {
        value: '6',
        label: 'Batam'
    },
    {
        value: '7',
        label: 'Balikpapan'
    },
    {
        value: '8',
        label: 'Surabaya'
    },
]

const BRACKET_PRICE_LIST = [
    {
        value: '1',
        label: '< Rp 500 Juta'
    },
    {
        value: '2',
        label: 'Rp 500 Juta - 1M'
    },
    {
        value: '3',
        label: 'Rp 1M - 2M'
    },
    {
        value: '4',
        label: 'Rp 2M - 5M'
    },
    {
        value: '5',
        label: 'Rp 5M - 10 M'
    },
    {
        value: '6',
        label: '> 10 M'
    },
]

export default PilarProjectList

import React, { useReducer, useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { Grid, TextField, Button, MenuItem, FormControlLabel, Checkbox, FormGroup, Typography/* , Switch */, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// Componenets
import LoadingScreen from '../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import TextArea from '../../_components/_form/TextArea';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
// import Autocomplete from '@mui/lab/Autocomplete';

// Icons
import List from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import { generalErrorHandler, numberToCurrency } from '../../_utils/Helper';

interface Props {
    listingId: string | null;
    mode: 'add' | 'edit';
}

interface IState {
    cluster_id: string;
    cluster_label: string;
    is_star: boolean;
    name: string;
    stock: number;
    property_type: string;
    address: string;
    latitude: string;
    longitude: string;
    matterport: string | null;
    sinarmas_landing_url: string | null;
    price: string;
    price_installment: string
    term_of_payment: string[];
    property_area: string;
    land_area: string;
    floor: string;
    bedroom: string;
    bathroom: string;
    maidroom: string;
    furnish: string;
    direction: string;
    view: string;
    tower: string;
    material_specification: string;
    electric_watt: string;
    smart_home: string;
    facilities: string[];
    meta_title: string;
    meta_description: string;
    tags: any;
    specifications: {
        id: number
        title: string
        description: string
    }[]
}

interface IErrorState {
    cluster_id: string;
    name: string;
    stock: string;
    property_type: string;
    address: string;
    latitude: string;
    longitude: string;
    price: string;
    property_area: string;
    floor: string;
    term_of_payment: string[]
    specifications: {
        title: string
        description: string
    }[];
}

interface IAction {
    name: string,
    value: any,
    type: string
}

interface IRefs {
    [key: string]: React.RefObject<HTMLInputElement>
}

const useStyles = makeStyles({
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    subTitle: {
        width: '100%',
        fontSize: '20px',
        borderBottom: '1px solid #0000004D'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            marginBottom: '15px'
        }
    },
    listItem: {
        width: '100%',
        display: 'flex'
    }
})

const ListingDetailForm = (props: Props) => {
    const API_URL = process.env.REACT_APP_API_URL + '/listing';
    const params: any = useParams();
    const history = useHistory();
    const classes = useStyles();

    const initialState: IState = {
        cluster_id: '',
        cluster_label: '',
        is_star: false,
        name: '',
        stock: 0,
        property_type: '',
        address: '',
        latitude: '',
        longitude: '',
        matterport: null,
        sinarmas_landing_url: null,
        price: '',
        price_installment: '',
        term_of_payment: [],
        property_area: '',
        land_area: '',
        floor: '',
        bedroom: '',
        bathroom: '',
        maidroom: '',
        furnish: '',
        direction: '',
        view: '',
        tower: '',
        material_specification: '',
        electric_watt: '',
        smart_home: '',
        facilities: [],
        meta_title: '',
        meta_description: '',
        tags: [],
        specifications: [],
    };

    const initialErrorState: IErrorState = {
        cluster_id: '',
        name: '',
        stock: '',
        property_type: '',
        address: '',
        latitude: '',
        longitude: '',
        price: '',
        property_area: '',
        floor: '',
        term_of_payment: [],
        specifications: [],
    };

    const propertyTypes = [
        'Residential',
        'Kavling',
    ];

    const shophousePropertyTypes = [
        'Shophouse',
        'Business Loft',
        'Kiosk',
        'Warehouse',
        'Boutique SOHO'
    ];

    const currencyAttributes = [
        'price',
        'price_installment',
    ];

    const defaultFacilities = [
        {
            value: 'f_private_pool',
            label: 'Private Pool'
        },
        {
            value: 'f_indoor_pool',
            label: 'Indoor Pool'
        },
        {
            value: 'f_outdoor_pool',
            label: 'Outdoor Pool'
        },
        {
            value: 'f_air_conditioner',
            label: 'AC'
        },
        {
            value: 'f_water_heater',
            label: 'Water Heater'
        },
        {
            value: 'f_access_card',
            label: 'Access Card'
        },
        {
            value: 'f_stove',
            label: 'Stove'
        },
        {
            value: 'f_hood',
            label: 'Hood'
        },
        {
            value: 'f_oven',
            label: 'Oven'
        },
        {
            value: 'f_kitchen_cabinet',
            label: 'Kitchen Cabinet'
        },
        {
            value: 'f_kitchen_island',
            label: 'Kitchen Island'
        },
        {
            value: 'f_sink',
            label: 'Sink'
        },
        {
            value: 'f_wardrobe',
            label: 'Wardrobe'
        },
        {
            value: 'f_bathtube',
            label: 'Bathtub'
        },
        {
            value: 'f_shower',
            label: 'Shower'
        },
        {
            value: 'f_closet',
            label: 'Closet'
        },
        {
            value: 'f_basin',
            label: 'Basin'
        },
        {
            value: 'f_vanity',
            label: 'Vanity'
        },
        {
            value: 'f_mirror',
            label: 'Mirror'
        },
        {
            value: 'f_balcony',
            label: 'Balcony'
        }
    ];

    const electricsList = [450, 900, 1300, 2200, 3300, 3500, 4400, 5500, 6600, 7600, 7700, 8000, 9500, 10000,
        10600, 11000, 12700, 13200, 16500, 17600, 19000, 22000, 23000, 24000, 30500, 33000, 38100, 41500, 47500, 53000, 61000, 66000,
        76000, 82500, 85000, 95000, 0
    ];

    // const directionList = [
    //     'North',
    //     'North East',
    //     'East',
    //     'South East',
    //     'South',
    //     'South West',
    //     'West',
    //     'North West'
    // ];

    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            if (currencyAttributes.includes(action.name)) {
                return {
                    ...state,
                    [action.name]: action.value.replace(/,/g, '')
                }
            }

            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return { ...initialErrorState };
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [isLoading, setIsLoading] = useState(false);
    const [firstInit, setFirstInit] = useState(true);
    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);
    const [facilities, setFacilities] = useState(defaultFacilities);
    // const [tagList, setTagList] = useState<Array<IListTag>>([]);
    const [checkboxAllFacilities, setCheckboxAllFacilities] = useState(false);
    const [clusterCategory, setClusterCategory] = useState("");
    const [disableInputs/* , setDisableInputs */] = useState(false);

    const parsedSelectedSpecifications = useMemo(() => inputState.specifications.map(specInput => specInput.title), [inputState.specifications])

    useEffect(() => {
        if (params.id) {
            loadData();
        } else {
            setFirstInit(false);
        }
        // loadFormData()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!firstInit) {
            const newState = { ...inputState };
            newState.facilities = [];
            newState.floor = '';

            if (newState.property_type === 'Apartment') {
                newState.land_area = '';
            } else if (newState.property_type === 'Kavling') {
                newState.property_area = '';
                newState.floor = '';
                newState.bedroom = '';
                newState.bathroom = '';
                newState.maidroom = '';
                newState.furnish = '';
                newState.view = '';
                newState.material_specification = '';
                newState.electric_watt = '';
                newState.smart_home = '';
            }

            checkFacilities(newState.property_type);
            setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [inputState.property_type]);

    useEffect(() => {
        const filteredFacilities = checkFacilities(inputState.property_type);

        setCheckboxAllFacilities(filteredFacilities.length === inputState.facilities.length);
        //eslint-disable-next-line
    }, [inputState.facilities]);

    const checkFacilities = (propertyType: string) => {
        let filteredFacilities = defaultFacilities.slice();

        filteredFacilities = filteredFacilities.filter(item => {
            if (propertyType !== 'Apartment') {
                return item.value === 'f_private_pool';
            } else {
                return true;
            }
        });

        setFacilities(filteredFacilities);
        return filteredFacilities;
    }

    const loadData = () => {
        setIsLoading(true);
        DefaultAxios.get(`${API_URL}/${params.id}`)
            .then(res => {
                const data = res.data;
                const newInputState: any = { ...initialState };

                for (let key in data) {
                    if (data[key] !== null) {
                        if (key === 'is_star') {
                            newInputState.is_star = !!data[key];
                        } else if (key === 'assigned_facilities') {
                            newInputState.facilities = data[key];
                        } else {
                            newInputState[key] = data[key];
                        }
                    }
                }

                setClusterCategory(res.data.cluster_category);
                setInputState({ name: '', value: newInputState, type: 'REPLACE_STATE' });
                setFirstInit(false);
                const filteredFacilities = checkFacilities(newInputState.property_type);
                setCheckboxAllFacilities(filteredFacilities.length === newInputState.facilities.length);

                // Check if user is not admin and listing is published
                // then disable all inputs except price and stock
                // for now will be disabled
                // if (!data.is_admin || data.is_published) {
                //     setDisableInputs(true);
                // }
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    // const loadFormData = (state?: IState) => {
    //     DefaultAxios.get(`${process.env.REACT_APP_API_URL}/autocomplete/tag`)
    //         .then(res => {
    //             setTagList(res.data);
    //         });
    // }

    const refs: IRefs = {
        cluster_id: useRef<HTMLInputElement>(null),
        name: useRef<HTMLInputElement>(null),
        stock: useRef<HTMLInputElement>(null),
        property_type: useRef<HTMLInputElement>(null),
        address: useRef<HTMLInputElement>(null),
        latitude: useRef<HTMLInputElement>(null),
        longitude: useRef<HTMLInputElement>(null),
        price: useRef<HTMLInputElement>(null),
        floor: useRef<HTMLInputElement>(null)
    }

    const scrollToRef = (ref: React.RefObject<HTMLInputElement>) => {
        window.scrollTo({ top: ref.current!.offsetTop - 64, behavior: 'smooth' })
    }

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const name = target.name;
        let value: any = target.value;

        if (name === 'facilities') {
            const tmp = inputState.facilities.slice();
            if (target.checked) {
                tmp.push(target.value);
            } else {
                const index = tmp.indexOf(target.value);
                if (index > -1) {
                    tmp.splice(index, 1);
                }
            }
            value = tmp;
        } else if (name === 'tags') {
            value = [target.value];
        } else if (target.type === 'checkbox') {
            value = target.checked;
        }

        setInputState({ name, value, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        // let isApartment = false;
        const newState = { ...inputState };
        newState.cluster_id = String(value.id);
        newState.address = value.address;

        if (value.category === 'apartment') {
            newState.property_type = 'Apartment';
            // isApartment = true;
        } else if (value.category === 'shophouse') {
            newState.property_type = 'Ruko';
        } else {
            newState.property_type = 'Residential';
        }

        setClusterCategory(value.category);
        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleAllFacilities = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFacilities: string[] = [];
        const filteredFacilities = checkFacilities(inputState.property_type);
        if (event.target.checked) {
            filteredFacilities.map(item => {
                newFacilities.push(item.value);
                return true;
            });
        }
        setInputState({ name: 'facilities', value: newFacilities, type: 'SET_ITEM' });
    }

    const handleAddList = () => {
        const lists = inputState['term_of_payment'].slice();
        lists.push('');

        setInputState({ name: 'term_of_payment', value: lists, type: 'SET_ITEM' });
    }

    const handleAddListSpecification = () => {
        const lists = inputState['specifications'].slice();
        lists.push({
            id: -1,
            title: '',
            description: '',
        });

        setInputState({ name: 'specifications', value: lists, type: 'SET_ITEM' });
    }

    const handleListChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const lists = inputState['term_of_payment'].slice();
        lists[index] = e.target.value;

        setInputState({ name: 'term_of_payment', value: lists, type: 'SET_ITEM' });
    }

    const handleListChangedSpecifications = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, index: number) => {
        const lists = inputState['specifications'].slice();
        lists[index] = {
            ...lists[index],
            [e.target.name]: e.target.value,
        }

        setInputState({ name: 'specifications', value: lists, type: 'SET_ITEM' });
    }

    const handleListDeleted = (index: number) => {
        const lists = inputState['term_of_payment'].slice();
        lists.splice(index, 1);

        setInputState({ name: 'term_of_payment', value: lists, type: 'SET_ITEM' });
    }

    const handleListDeletedSpecifications = (index: number) => {
        const lists = inputState['specifications'].slice();
        lists.splice(index, 1);

        setInputState({ name: 'specifications', value: lists, type: 'SET_ITEM' });
    }

    const renderCheckBoxFacility = (odd: boolean) => {
        const orderedFacilities: { value: string, label: string }[] = facilities.slice()
            .sort(({ label: a }, { label: b }) => (a < b) ? -1 : (a > b) ? 1 : 0);

        const checkboxes = orderedFacilities.map((item, key) => {
            const condition = odd ? key % 2 !== 0 : key % 2 === 0;
            if (condition) {
                return (
                    <FormControlLabel
                        key={key}
                        control={
                            <Checkbox
                                checked={inputState.facilities.includes(item.value)}
                                onChange={handleChanged}
                                name="facilities"
                                value={item.value}
                                color="primary"
                                disabled={disableInputs}
                            />
                        }
                        label={item.label}
                    />
                );
            }
            return null;
        })

        return checkboxes;
    }

    const checkValidation = () => {
        let isValid = true;
        let firstError = '';
        const newErrorState = { ...initialErrorState };

        if (!inputState.cluster_id) {
            if (!firstError) firstError = 'cluster_id';
            newErrorState.cluster_id = 'Cluster wajib diisi';
            isValid = false;
        }

        if (!inputState.name) {
            if (!firstError) firstError = 'name';
            newErrorState.name = 'Nama wajib diisi';
            isValid = false;
        }

        if (!inputState.stock && inputState.stock !== 0) {
            if (!firstError) firstError = 'stock';
            newErrorState.stock = 'Stock wajib diisi';
            isValid = false;
        }

        if (!inputState.property_type) {
            if (!firstError) firstError = 'property_type';
            newErrorState.property_type = 'Property Type wajib diisi';
            isValid = false;
        }

        if (!inputState.address) {
            if (!firstError) firstError = 'address';
            newErrorState.address = 'Address wajib diisi';
            isValid = false;
        }

        // if (!isValidLatLng(inputState.latitude)) {
        //     if (!firstError) firstError = 'latitude';
        //     newErrorState.latitude = 'Format latitude salah';
        //     isValid = false;
        // }

        // if (!isValidLatLng(inputState.longitude)) {
        //     if (!firstError) firstError = 'longitude';
        //     newErrorState.longitude = 'Format longitude salah';
        //     isValid = false;
        // }

        if (!inputState.price) {
            if (!firstError) firstError = 'price';
            newErrorState.price = 'Price wajib diisi';
            isValid = false;
        }

        // if (['Residential', 'Ruko'].includes(inputState.property_type) && inputState.property_area && inputState.land_area && (parseFloat(inputState.property_area) > parseFloat(inputState.land_area))) {
        //     if (!firstError) firstError = 'property_area';
        //     newErrorState.property_area = 'Property Area tidak boleh lebih besar dari Land Area';
        //     isValid = false;
        // }

        for (let key in inputState.term_of_payment) {
            if (!inputState.term_of_payment[key]) {
                newErrorState.term_of_payment[key] = 'Term of Payment wajib diisi apabila di tambahkan';
                isValid = false;
            } else {
                newErrorState.term_of_payment[key] = '';
            }
        }

        for (let index in inputState.specifications) {
            if (!newErrorState.specifications[index]) {
                newErrorState.specifications[index] = {
                    title: '',
                    description: '',
                }
            }

            if (!inputState.specifications[index].title) {
                newErrorState.specifications[index].title = 'Title spesifikasi wajib diisi apabila di tambahkan';
                isValid = false;
            } else {
                newErrorState.specifications[index].title = '';
            }

            if (!inputState.specifications[index].description) {
                newErrorState.specifications[index].description = 'Deskripsi spesifikasi wajib diisi apabila di tambahkan';
                isValid = false;
            } else {
                newErrorState.specifications[index].description = '';
            }
        }

        if (inputState.floor) {
            const explode = inputState.floor.split(',');
            const duplicate = explode.filter((item, index) => explode.indexOf(item) !== index);

            if (duplicate.length) {
                if (!firstError) firstError = 'floor';
                newErrorState.floor = 'Floor memiliki nilai yang duplicate';
                isValid = false;
            }
        }

        if (firstError) scrollToRef(refs[firstError]);
        setErrorState({ name: '', value: newErrorState, type: 'REPLACE_STATE' });
        return isValid;
    }

    // const handleChangeTag = (value: any)  => {
    //     setInputState({ name: 'tags', value: value, type: 'SET_ITEM' });
    // }

    const handleSubmit = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;

        if (params.id) {
            axios = DefaultAxios.patch(`${API_URL}/${params.id}`, inputState);
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setIsLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: "Submit data berhasil",
                    icon: 'success',
                    onAfterClose: () => {
                        if (params.id) {

                        } else {
                            history.push('/listing');
                        }
                    },
                    timer: 1000
                });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    // const newTag = (e: any) => {
    //     if (e.keyCode === 13 && e.target.value) {
    //         let val = {
    //             id: 'new',
    //             name: e.target.value
    //         }
    //         let oldValue = inputState.tags
    //         let newValue = oldValue.concat(val)
    //         setInputState({ name: 'tags', value: newValue, type: 'SET_ITEM' });
    //     }
    // }

    return (
        <Fragment>
            <LoadingScreen open={isLoading} fullScreen />
            {/* <Grid item xs={12} className={classes.switchContainer}>
                <Typography>
                    Star Listing
                </Typography>
                <Switch
                    color="primary"
                    name="is_star"
                    value={true}
                    checked={inputState.is_star}
                    onChange={handleChanged}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                />
            </Grid> */}

            <Grid item xs={12} ref={refs.cluster_id}>
                <AsyncAutoComplete
                    label="Cluster"
                    name="cluster_id"
                    initialQuery={inputState.cluster_label}
                    onChange={handleAutocomplete}
                    onInputChange={handleAutocompleteInputChanged}
                    url={`${process.env.REACT_APP_API_URL}/autocomplete/cluster`}
                    iconSearch
                    errorText={errorState.cluster_id}
                    disabled={!!params.id}
                />
            </Grid>

            {(['residential', 'shophouse'].includes(clusterCategory)) && (!params.id || (params.id && clusterCategory === 'shophouse')) &&
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        variant="outlined"
                        label="Property Type"
                        name="property_type"
                        value={inputState.property_type}
                        onChange={handleChanged}
                        error={!!errorState.property_type}
                        helperText={errorState.property_type}
                        ref={refs.property_type}
                    >
                        {
                            clusterCategory === 'residential'
                                ? propertyTypes.map((propertyType, key) => {
                                    return <MenuItem value={propertyType} key={key}>{propertyType}</MenuItem>
                                })
                                : shophousePropertyTypes.map((propertyType, key) => {
                                    return <MenuItem value={propertyType === 'Shophouse' ? 'Ruko' : propertyType} key={key}>{propertyType}</MenuItem>
                                })
                        }
                    </TextField>
                </Grid>
            }

            {/* {inputState.property_type === 'Ruko' &&
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Kategori Ruko"
                        name="tags"
                        value={typeof inputState.tags[0] === "undefined" ? "" : inputState.tags[0]}
                        onChange={handleChanged}
                        disabled={disableInputs}
                    >
                        <MenuItem value="">Silahkan Pilih Kategori</MenuItem>
                        <MenuItem value="Shophouse">Shophouse</MenuItem>
                        <MenuItem value="Business Loft">Business Loft</MenuItem>
                        <MenuItem value="Kiosk">Kiosk</MenuItem>
                        <MenuItem value="Warehouse">Warehouse</MenuItem>
                    </TextField>
                </Grid>
            } */}

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Nama"
                    name="name"
                    value={inputState.name}
                    onChange={handleChanged}
                    error={!!errorState.name}
                    helperText={errorState.name}
                    ref={refs.name}
                    disabled={disableInputs}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Address"
                    name="address"
                    value={inputState.address}
                    onChange={handleChanged}
                    error={!!errorState.address}
                    helperText={errorState.address}
                    ref={refs.address}
                    disabled={disableInputs}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Stock"
                    name="stock"
                    value={inputState.stock}
                    onChange={handleChanged}
                    error={!!errorState.stock}
                    helperText={errorState.stock}
                    ref={refs.stock}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Price"
                    name="price"
                    value={numberToCurrency(inputState.price)}
                    onChange={handleChanged}
                    error={!!errorState.price}
                    helperText={errorState.price}
                    ref={refs.price}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Cicilan Price"
                    name="price_installment"
                    value={inputState.price_installment ? numberToCurrency(inputState.price_installment) : ''}
                    onChange={handleChanged}
                />
            </Grid>

            {/* <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Latitude"
                    name="latitude"
                    value={inputState.latitude}
                    onChange={handleChanged}
                    error={!!errorState.latitude}
                    helperText={errorState.latitude}
                    ref={refs.latitude}
                    disabled={disableInputs}
                />
            </Grid> */}

            {/* <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Longitude"
                    name="longitude"
                    value={inputState.longitude}
                    onChange={handleChanged}
                    error={!!errorState.longitude}
                    helperText={errorState.longitude}
                    ref={refs.longitude}
                    disabled={disableInputs}
                />
            </Grid> */}

            <Grid item xs={12}>
                <TextField
                    label="Matterport Link"
                    name="matterport"
                    value={inputState.matterport}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    placeholder="Contoh: https://my.matterport.com/show/?m=Lu8V2q29ju9"
                    onChange={handleChanged}
                    disabled={disableInputs}
                    // error={!!errorState.matterport}
                    // helperText={errorState.matterport}
                    ref={refs.matterport}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Landing URL"
                    name="sinarmas_landing_url"
                    value={inputState.sinarmas_landing_url}
                    variant="outlined"
                    fullWidth
                    onChange={handleChanged}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Contoh: https://sinarmasland.com/development/residential/hylands"
                />
            </Grid>

            {
                inputState.property_type !== 'Kavling' &&
                <Grid item xs={inputState.property_type === 'Residential' ? 6 : 12}>
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        label="Property Area"
                        name="property_area"
                        value={inputState.property_area}
                        onChange={handleChanged}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                        }}
                        error={!!errorState.property_area}
                        helperText={errorState.property_area}
                        disabled={disableInputs}
                    />
                </Grid>
            }

            {
                inputState.property_type !== 'Apartment' &&
                <Grid item xs={inputState.property_type === 'Residential' ? 6 : 12}>
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        label="Land Area"
                        name="land_area"
                        value={inputState.land_area}
                        onChange={handleChanged}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>,
                        }}
                        disabled={disableInputs}
                    />
                </Grid>
            }

            {
                inputState.property_type !== 'Kavling' &&
                <>
                    <Grid item xs={12}>
                        {
                            inputState.property_type === 'Apartment'
                                ? <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Floor"
                                    name="floor"
                                    value={inputState.floor}
                                    onChange={handleChanged}
                                    disabled={disableInputs}
                                    placeholder="1,2,3,12,20,25,dst"
                                    error={!!errorState.floor}
                                    helperText={errorState.floor}
                                    ref={refs.floor}
                                />
                                : <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    label="Floor"
                                    name="floor"
                                    value={inputState.floor}
                                    onChange={handleChanged}
                                    disabled={disableInputs}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                    <MenuItem value="5">5</MenuItem>
                                    <MenuItem value="6">6</MenuItem>
                                    <MenuItem value="7">7</MenuItem>
                                    <MenuItem value="8">8</MenuItem>
                                    <MenuItem value="9">9</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                </TextField>
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Bedroom"
                            name="bedroom"
                            value={inputState.bedroom}
                            onChange={handleChanged}
                            disabled={disableInputs}
                        >
                            <MenuItem value="0">Studio</MenuItem>
                            <MenuItem value="1">1 Bedroom</MenuItem>
                            <MenuItem value="2">2 Bedrooms</MenuItem>
                            <MenuItem value="3">3 Bedrooms</MenuItem>
                            <MenuItem value="4">4 Bedrooms</MenuItem>
                            <MenuItem value="5">5 Bedrooms</MenuItem>
                            <MenuItem value="6">6 Bedrooms</MenuItem>
                            <MenuItem value="7">7 Bedrooms</MenuItem>
                            <MenuItem value="8">8 Bedrooms</MenuItem>
                            <MenuItem value="9">9 Bedrooms</MenuItem>
                            <MenuItem value="10">10 Bedrooms</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Bathroom"
                            name="bathroom"
                            value={inputState.bathroom}
                            onChange={handleChanged}
                            disabled={disableInputs}
                        >
                            <MenuItem value="0">Tidak ada</MenuItem>
                            <MenuItem value="1">1 Bathroom</MenuItem>
                            <MenuItem value="2">2 Bathrooms</MenuItem>
                            <MenuItem value="3">3 Bathrooms</MenuItem>
                            <MenuItem value="4">4 Bathrooms</MenuItem>
                            <MenuItem value="5">5 Bathrooms</MenuItem>
                            <MenuItem value="6">6 Bathrooms</MenuItem>
                            <MenuItem value="7">7 Bathrooms</MenuItem>
                            <MenuItem value="8">8 Bathrooms</MenuItem>
                            <MenuItem value="9">9 Bathrooms</MenuItem>
                            <MenuItem value="10">10 Bathrooms</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Maid Room"
                            name="maidroom"
                            value={inputState.maidroom}
                            onChange={handleChanged}
                            disabled={disableInputs}
                        >
                            <MenuItem value="0">Tidak Ada</MenuItem>
                            <MenuItem value="1">1 Maid Room</MenuItem>
                            <MenuItem value="2">2 Maid Rooms</MenuItem>
                            <MenuItem value="3">3 Maid Rooms</MenuItem>
                            <MenuItem value="4">4 Maid Rooms</MenuItem>
                            <MenuItem value="5">5 Maid Rooms</MenuItem>
                            <MenuItem value="6">6 Maid Rooms</MenuItem>
                            <MenuItem value="7">7 Maid Rooms</MenuItem>
                            <MenuItem value="8">8 Maid Rooms</MenuItem>
                            <MenuItem value="9">9 Maid Rooms</MenuItem>
                            <MenuItem value="10">10 Maid Rooms</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Furnish"
                            name="furnish"
                            value={inputState.furnish}
                            onChange={handleChanged}
                            disabled={disableInputs}
                        >
                            <MenuItem value="non">Non Furnish</MenuItem>
                            <MenuItem value="semi">Semi Furnish</MenuItem>
                            <MenuItem value="full">Full Furnish</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Electric Watt"
                            name="electric_watt"
                            value={inputState.electric_watt}
                            onChange={handleChanged}
                            disabled={disableInputs}
                        >
                            {electricsList.map((element, idx) => (<MenuItem key={idx} value={element}>{element === 0 ? 'Lainnya' : element}</MenuItem>))}
                        </TextField>
                    </Grid>
                </>
            }

            {/* <Grid item xs={12}>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Direction"
                    name="direction"
                    value={inputState.direction}
                    onChange={handleChanged}
                    disabled={disableInputs}
                >
                    {directionList.map((element, idx) => (<MenuItem key={idx} value={element}>{element}</MenuItem>))}
                </TextField>
            </Grid> */}

            <Grid item xs={inputState.property_type !== 'Kavling' ? 6 : 12}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Direction"
                    name="direction"
                    value={inputState.direction}
                    onChange={handleChanged}
                    disabled={disableInputs}
                />
            </Grid>

            {
                inputState.property_type !== 'Kavling' &&
                <>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="View"
                            name="view"
                            value={inputState.view}
                            onChange={handleChanged}
                            disabled={disableInputs}
                        />
                    </Grid>

                    {inputState.property_type === 'Apartment' &&
                        <Grid item xs={12}>
                            <TextArea
                                label="Tower"
                                name="tower"
                                value={inputState.tower}
                                onChange={handleChanged}
                                disabled={disableInputs}
                            />
                        </Grid>
                    }

                    {/* {inputState.property_type !== 'Apartment' &&
                        <Grid item xs={12}>
                            <TextArea
                                label="Material Specification"
                                name="material_specification"
                                value={inputState.material_specification}
                                onChange={handleChanged}
                                disabled={disableInputs}
                            />
                        </Grid>
                    } */}

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Smart Home"
                            name="smart_home"
                            value={inputState.smart_home}
                            onChange={handleChanged}
                            placeholder="Pintu, Lampu, dll"
                            disabled={disableInputs}
                        />
                    </Grid>
                </>
            }

            {
                !['Apartment', 'Kavling', ''].includes(inputState.property_type) &&
                <Grid item xs={12} className={classes.listContainer}>
                    <Typography className={classes.subTitle}>
                        <List /> Specification
                    </Typography>
                    {
                        inputState.specifications.map((item, key) => {
                            return (
                                <div className={classes.listItem} key={key}>
                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        label="Title"
                                        name="title"
                                        value={item.title}
                                        onChange={(e) => handleListChangedSpecifications(e, key)}
                                        error={!!errorState.specifications[key]?.title}
                                        helperText={errorState.specifications[key]?.title}
                                        disabled={disableInputs}
                                        style={{ maxWidth: '25%' }}
                                    >
                                        {specificationTypes[inputState.property_type as ('Residential' | 'Kiosk' | 'Ruko' | 'Business Loft' | 'Warehouse' | 'Boutique SOHO')].map(spec =>
                                            <MenuItem
                                                key={spec}
                                                value={spec}
                                                disabled={parsedSelectedSpecifications.includes(spec)}
                                            >
                                                {spec}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                    <div style={{ marginRight: 16 }} />
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Description"
                                        value={item.description}
                                        name="description"
                                        onChange={(e) => handleListChangedSpecifications(e, key)}
                                        error={!!errorState.specifications[key]?.description}
                                        helperText={errorState.specifications[key]?.description}
                                        disabled={disableInputs}
                                    />
                                    {
                                        !disableInputs &&
                                        <IconButton disabled={disableInputs} aria-label="delete" onClick={() => handleListDeletedSpecifications(key)}>
                                            <DeleteIcon color="secondary" />
                                        </IconButton>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        (!disableInputs && inputState.specifications.length < specificationTypes[inputState.property_type as ('Residential' | 'Kiosk' | 'Ruko' | 'Business Loft' | 'Warehouse' | 'Boutique SOHO')].length) &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddListSpecification()}
                        >
                            Add Specification
                        </Button>
                    }
                </Grid>
            }

            <Grid item xs={12} className={classes.listContainer}>
                <Typography className={classes.subTitle}>
                    <List /> Term of Payment
                </Typography>
                {
                    inputState.term_of_payment.map((item, key) => {
                        return <div className={classes.listItem} key={key}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="description"
                                value={item}
                                onChange={(e) => handleListChanged(e, key)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DescriptionIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={!!errorState.term_of_payment[key]}
                                helperText={errorState.term_of_payment[key]}
                                disabled={disableInputs}
                            />
                            {
                                !disableInputs &&
                                <IconButton disabled={disableInputs} aria-label="delete" onClick={() => handleListDeleted(key)}>
                                    <DeleteIcon color="secondary" />
                                </IconButton>
                            }
                        </div>
                    })
                }
                {
                    !disableInputs &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddList()}
                    >
                        Add Term of Payment
                    </Button>
                }
            </Grid>

            {/* <Grid item xs={12}>
                <Autocomplete
                    multiple
                    id="tags-standard"
                    value={inputState.tags}
                    options={tagList}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        handleChangeTag(newValue)
                    }}
                    noOptionsText={'Press Enter to add new category.'}
                    getOptionSelected={(option: any, value: any) => option.id === value.id}
                    filterSelectedOptions
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Category"
                        placeholder="Choose Category"
                        onKeyDown={newTag}
                        helperText="Press enter to add new category. Notes: new category will save after submit form"
                    />
                    )}
                    disabled={disableInputs}
                />
            </Grid> */}

            {
                inputState.property_type !== 'Kavling' &&
                <>
                    <Grid item xs={12}>
                        <Typography>
                            <strong>Facilities</strong>
                        </Typography>
                    </Grid>
                    {
                        inputState.property_type === 'Apartment' &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleAllFacilities}
                                        name="checkedB"
                                        color="primary"
                                        checked={checkboxAllFacilities}
                                        disabled={disableInputs}
                                    />
                                }
                                label="Pilih Semua"
                            />
                        </Grid>
                    }

                    <Grid item xs={6}>
                        <FormGroup>
                            {renderCheckBoxFacility(false)}
                        </FormGroup>
                    </Grid>

                    <Grid item xs={6}>
                        <FormGroup>
                            {renderCheckBoxFacility(true)}
                        </FormGroup>
                    </Grid>
                </>
            }

            {/* <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Meta Title"
                    name="meta_title"
                    value={inputState.meta_title}
                    onChange={handleChanged}
                    disabled={disableInputs}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Meta Description"
                    name="meta_description"
                    value={inputState.meta_description}
                    onChange={handleChanged}
                    disabled={disableInputs}
                />
            </Grid> */}

            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Grid>
        </Fragment>
    );
}

export default ListingDetailForm;

// const specificationTypesTemp = ['Pondasi', 'Dinding', 'Lantai', 'Struktur Utama', 'Finishing Dinding', 'Atap', 'Penutup Atap', 'Plafond', 'Pintu', 'Pintu Utama', 'Jendela', 'Sanitair', 'Listrik', 'Air', 'Lampu', 'Kusen', 'Tinggi Ceiling', 'Fasad', 'Telepon', 'Internet', 'Lift', 'Carport']
// const specificationTypes = {
//     'Residential': ['Pondasi', 'Dinding', 'Lantai', 'Finishing Dinding', 'Atap', 'Penutup Atap', 'Plafond', 'Pintu', 'Jendela', 'Sanitair', 'Listrik', 'Air', 'Lampu', 'Kusen', 'Carport'],
//     'Ruko': ['Pondasi', 'Dinding', 'Lantai', 'Atap', 'Penutup Atap', 'Plafond', 'Pintu', 'Jendela', 'Sanitair', 'Listrik', 'Air', 'Fasad', 'Telepon', 'Internet'],
//     'Kiosk': ['Pondasi', 'Dinding', 'Lantai', 'Plafond', 'Pintu', 'Listrik', 'Air', 'Tinggi Ceiling'],
//     'Business Loft': ['Pondasi', 'Dinding', 'Lantai', 'Struktur Utama', 'Penutup Atap', 'Plafond', 'Pintu', 'Pintu Utama', 'Jendela', 'Sanitair', 'Listrik', 'Air', 'Fasad', 'Telepon', 'Internet', 'Lift'],
// }

/**
 * Request by User to Show All Types
 */
const specificationTypesAll = ['Pondasi', 'Dinding', 'Lantai', 'Finishing Dinding', 'Atap', 'Penutup Atap', 'Plafond', 'Pintu', 'Jendela', 'Sanitair', 'Listrik', 'Air', 'Lampu', 'Kusen', 'Carport', 'Fasad', 'Telepon', 'Internet', 'Tinggi Ceiling', 'Struktur Utama', 'Pintu Utama', 'Lift'];
const specificationTypes = {
    'Residential': specificationTypesAll,
    'Ruko': specificationTypesAll,
    'Kiosk': specificationTypesAll,
    'Business Loft': specificationTypesAll,
    'Warehouse': specificationTypesAll,
    'Boutique SOHO': specificationTypesAll,
}

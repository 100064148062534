import React, { useState, useEffect } from "react";
import { SnackbarProvider } from 'notistack'
import { makeStyles, createStyles } from '@mui/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from './_components/PrivateRoute';
import MenuBar from './_components/MenuBar';
import Dashboard from './_pages/Dashboard';
import SignInSide from './_pages/SignInSide';
import './App.css';
import DefaultAxios from "./_utils/DefaultAxios";

// Cluster Category
import ClusterCategory from "./_pages/_clusterCategory/ClusterCategoryList";
// Cluster List
import ClusterList from "./_pages/_cluster/ClusterList";
import ClusterForm from "./_pages/_cluster/ClusterForm";
import ModalDetailCluster from "./_pages/_cluster/ModalDetailCluster";

// Cluster Category
import ClusterCategoryList from "./_pages/_clusterCategory/ClusterCategoryList";

// Project
import ProjectForm from "./_pages/_project/ProjectForm";
import ProjectList from "./_pages/_project/ProjectList";

// Listing
import ListingList from "./_pages/_listingList/ListingList";
import ListingForm from "./_pages/_listing/ListingForm";
import ModalDetailListing from "./_pages/_listing/ModalDetailListing";

// Enquiry
import EnquiryList from "./_pages/_enquiry/EnquiryList";

// Site Setting
import SiteSetting from "./_pages/_siteSetting/SiteSetting";

// Profile Setting
import ProfileSetting from "./_pages/_profileSetting/ProfileSetting";

// User
import UserList from "./_pages/_user/UserList";

// Force Login
import ForceLogin from "./_pages/_user/ForceLogin";

// Agent External
import AgentExternalList from "./_pages/_agentExternal/AgentExternalList";

// Promotion Sort
import SortPromotion from "./_pages/_sortPromotion/SortPromotion";

// Agent
import AgentList from "./_pages/_agent/AgentList";

// Agent Office
import AgentOfficeList from "./_pages/_agentOffice/AgentOfficeList";

// Agent Report
import AgentWeeklyReport from "./_pages/_agentWeeklyReport/AgentWeeklyReport";
import Agent360Report from "./_pages/_agent360Report/Agent360Report";
import AgentPerformanceReport from "./_pages/_agentPerformanceReport/AgentPerformanceReport";
import CaptainPerformanceReport from "./_pages/_captainPerformanceReport/CaptainPerformanceReport";
import AgencyPerformanceReport from "./_pages/_agencyPerformanceReport/AgencyPerformanceReport";
import AssignAgentBsd from "./_pages/_assignAgentBsd/AssignAgentBsd";

// Wish For Home
import WishForHomePage from "./_pages/_wishForHomePage/WishForHomePage";
import RoleList from "./_pages/_role/RoleList";

// Site Homepage Banner
import SiteHomepageBannerPage from "./_pages/_siteHomepageBanner/SiteHomepageBannerPage";

// Top 5 Agents
import Top5Agent from "./_pages/_top5Agent/Top5Agent";
import DrivePage from "./_pages/_drive/DrivePage";

// Website Feedback
import WebsiteFeedbackList from "./_pages/_websiteFeedback/WebsiteFeedbackList";

// Article
import ArticleList from "./_pages/_article/ArticleList";
import ArticleForm from "./_pages/_article/ArticleForm";
import LogAgentLogin from "./_pages/_logAgentLogin/LogAgentLogin";
import LogUserLogin from "./_pages/_logUserLogin/LogUserLogin";
import { Theme } from "@mui/material";
import CityList from "./_pages/_city/CityList";
import CityForm from "./_pages/_city/CityForm";
import SubProjectList from "./_pages/_subProject/SubProjectList";
import SubProjectForm from "./_pages/_subProject/SubProjectForm";

// KPR Logs
import LogKPR from "./_pages/_logKPR/LogKPR";

// Account
import AccountList from "./_pages/_account/AccountList"
import BlacklistSystemList from "./_pages/_systemBlacklist/BlacklistSystemList";

// LP Article
import LpArticle from "./_pages/_lpArticle/LpArticle";

// Page Tracking
import PageTracking from "./_pages/_pageTracking/PageTracking";
import moment from "moment";
import { PermissionProvider } from "./_providers/PermissionProvider";
import LogKPROld from "./_pages/_logKPR/LogKPROld";
import AccountTracker from "./_pages/_accountTracker/AccountTracker";
import AgentTeam from "./_pages/_agentTeam/AgentTeam";
import WishlistExport from "./_pages/_wishlistExport/WishlistExport";
import PilarProjectList from "./_pages/_pilarProject/PilarProjectList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: '#033a11'
    },
    error: {
      backgroundColor: '#c62828'
    }
  })
)

const App = () => {
  const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('permissions') || "{}"));

  const classes = useStyles()

  useEffect(() => {
    if (localStorage.getItem("jwt_token") !== null) {
      if (localStorage.getItem("permissions") === null || moment().unix() > Number(localStorage.getItem('reset_permission_timer'))) {
        fetchPermissions();
      }
    }
  }, [permissions]);

  const fetchPermissions = () => {
    DefaultAxios
      .get(`${process.env.REACT_APP_API_URL}/permissions`)
      .then(res => {
        setPermissions(res.data || {});
        localStorage.setItem('permissions', JSON.stringify(res.data || {}));
        localStorage.setItem('reset_permission_timer', String(moment().add(15, 'minutes').unix()));
      });
  }

  return (
    <PermissionProvider value={permissions}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={5000}
        disableWindowBlurListener
        hideIconVariant
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error
        }}
        onEntered={() => {
          window.navigator.vibrate([200, 100, 200])
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/login">
              <SignInSide></SignInSide>
            </Route>
            <PrivateRoute exact path="/" component={function () { return <MenuBar title="Dashboard"><Dashboard /></MenuBar> }} key={"/"} />

            {/* Force Login */}
            <PrivateRoute exact path="/user/force" component={function () { return <ForceLogin /> }} key={"/"} />

            <PrivateRoute exact path="/project/add" component={function () { return <MenuBar title="Project Form"><ProjectForm /></MenuBar> }} key={"/project/add"} />
            <PrivateRoute exact path="/project/edit/:id/:type?" component={function () { return <MenuBar title="Project Form"><ProjectForm /></MenuBar> }} key={"/project/edit/:id/:type?"} />
            <PrivateRoute exact path="/project" component={function () { return <MenuBar title="Project List"><ProjectList /></MenuBar> }} key={"/project"} />

            {/* Listing */}
            <PrivateRoute exact path="/listing/add" component={function () { return <MenuBar title="Add Listing"><ListingForm /></MenuBar> }} key={"/listing/add"} />
            <PrivateRoute exact path="/listing/edit/:id/:type?" component={function () { return <MenuBar title="Listing Form"><ListingForm /></MenuBar> }} key={"/listing/edit/:id/:type?"} />
            <PrivateRoute exact path="/listing" component={function () { return <MenuBar title="Listing"><ListingList /></MenuBar> }} key={"/listing"} />
            <PrivateRoute exact path="/listing/detail/:id" component={function () { return <ModalDetailListing /> }} key={"/listing/detail/:id"} />

            {/* Cluster Category */}
            <PrivateRoute exact path="/cluster-category" component={function () { return <MenuBar title="Cluster Category"><ClusterCategory /></MenuBar> }} key={"/cluster-category"} />
            <PrivateRoute exact path="/cluster" component={function () { return <MenuBar title="Cluster List"><ClusterList /></MenuBar> }} key={"/cluster"} />
            <PrivateRoute exact path="/cluster/edit/:id/:type?" component={function () { return <MenuBar title="Cluster Form"><ClusterForm /></MenuBar> }} key={"/cluster/edit/:id/:type?"} />
            <PrivateRoute exact path="/cluster/add" component={function () { return <MenuBar title="Cluster Form"><ClusterForm /></MenuBar> }} key={"/cluster/add"} />
            <PrivateRoute exact path="/cluster/detail/:id" component={function () { return <ModalDetailCluster /> }} key={"/cluster/detail/:id"} />
            <PrivateRoute exact path="/cluster/category/:id" component={function () { return <ClusterCategoryList /> }} key={"/cluster/category/:id"} />

            {/* Enquiry */}
            <PrivateRoute exact path="/enquiry" component={function () { return <MenuBar title="Enquiry List"><EnquiryList /></MenuBar> }} key={"/enquiry"} />

            {/* Site Setting */}
            <PrivateRoute exact path="/site-setting" component={function () { return <MenuBar title="Site Setting"><SiteSetting /></MenuBar> }} key={"/site-setting"} />

            {/* Profile Setting */}
            <PrivateRoute exact path="/profile-setting" component={function () { return <MenuBar title="Profile Setting"><ProfileSetting /></MenuBar> }} key={"/profile-setting"} />

            {/* User */}
            <PrivateRoute exact path="/user-list" component={function () { return <MenuBar title="User List"><UserList /></MenuBar> }} key={"/user-list"} />

            {/* Agent Office */}
            <PrivateRoute exact path="/agent-office" component={function () { return <MenuBar title="Agent Office"><AgentOfficeList /></MenuBar> }} key={"/agent-office"} />

            {/* Agent Team */}
            <PrivateRoute exact path="/agent-team" component={function () { return <MenuBar title="Agent Team"><AgentTeam /></MenuBar> }} key={"/agent-office"} />

            {/* Agent */}
            <PrivateRoute exact path="/agent" component={function () { return <MenuBar title="Agent List"><AgentList /></MenuBar> }} key={"/agent"} />

            {/* Agent External */}
            <PrivateRoute exact path="/agent-external" component={function () { return <MenuBar title="Agent External"><AgentExternalList /></MenuBar> }} key={"/agent-external"} />

            {/* Sort Promotion */}
            <PrivateRoute exact path="/sort-promotion" component={function () { return <MenuBar title="Sort Promotion"><SortPromotion /></MenuBar> }} key={"/sort-promotion"} />

            {/* Agent Weekly Report */}
            <PrivateRoute exact path="/agent-weekly-report" component={function () { return <MenuBar title="Agent Weekly Report"><AgentWeeklyReport /></MenuBar> }} key={"/agent-weekly-report"} />

            {/* Agent Weekly Report */}
            <PrivateRoute exact path="/agent-360-report" component={function () { return <MenuBar title="Agent 360 Report"><Agent360Report /></MenuBar> }} key={"/agent-weekly-report"} />

            {/* Agent Performance Report */}
            <PrivateRoute exact path="/agent-performance-report" component={function () { return <MenuBar title="Agent Performance Report"><AgentPerformanceReport /></MenuBar> }} key={"/agent-performance-report"} />

            {/* Captain Performance Report */}
            <PrivateRoute exact path="/captain-performance-report/:id" component={function () { return <MenuBar title="Captain Performance Report Detail"><AgentPerformanceReport type="captain" /></MenuBar> }} key={"/captain-performance-report/:id"} />
            <PrivateRoute exact path="/captain-performance-report" component={function () { return <MenuBar title="Captain Performance Report"><CaptainPerformanceReport /></MenuBar> }} key={"/captain-performance-report"} />

            {/* Agency Performance Report */}
            <PrivateRoute exact path="/agency-performance-report/:id" component={function () { return <MenuBar title="Agency Performance Report Detail"><AgentPerformanceReport type="agency" /></MenuBar> }} key={"/agency-performance-report/:id"} />
            <PrivateRoute exact path="/agency-performance-report" component={function () { return <MenuBar title="Agency Performance Report"><AgencyPerformanceReport /></MenuBar> }} key={"/agency-performance-report"} />

            {/* Assigned Agent BSD */}
            <PrivateRoute exact path="/assign-agent-bsd" component={function () { return <MenuBar title="Assign Agent BSD"><AssignAgentBsd /></MenuBar> }} key={"/assign-agent-bsd"} />

            {/* Wish For Home */}
            <PrivateRoute exact path="/wish-for-home" component={function () { return <MenuBar title="Wish For Home Page"><WishForHomePage /></MenuBar> }} key={"/wish-for-home"} />

            {/* Site Homepage Banner */}
            <PrivateRoute exact path="/site-homepage-banner" component={function () { return <MenuBar title="Homepage Banners"><SiteHomepageBannerPage /></MenuBar> }} key={"/site-homepage-banner"} />

            {/* Top 5 Agents */}
            <PrivateRoute exact path="/top-5-agents" component={function () { return <MenuBar title="Top 5 Agents"><Top5Agent /></MenuBar> }} key={"/top-5-agents"} />

            {/* Role */}
            <PrivateRoute exact path="/role" component={function () { return <MenuBar title="Manage Role"><RoleList /></MenuBar> }} key={"/role"} />

            {/* Drive */}
            <PrivateRoute exact path="/drive" component={function () { return <MenuBar title="Drive"><DrivePage /></MenuBar> }} key={"/drive"} />

            {/* Website Feedback */}
            <PrivateRoute exact path="/website-feedback" component={function () { return <MenuBar title="Website Feedback"><WebsiteFeedbackList /></MenuBar> }} key={"/website-feedback"} />

            {/* Article */}
            <PrivateRoute exact path="/article" component={function () { return <MenuBar title="Article List"><ArticleList /></MenuBar> }} key={"/article"} />
            <PrivateRoute exact path="/article/add" component={function () { return <MenuBar title="Article Add"><ArticleForm /></MenuBar> }} key={"/article/add"} />
            <PrivateRoute exact path="/article/edit/:id" component={function () { return <MenuBar title="Article Edit"><ArticleForm /></MenuBar> }} key={"/article/edit/:id"} />

            {/* LP Article */}
            <PrivateRoute exact path="/lp-article" component={function () { return <MenuBar title="LP Article"><LpArticle /></MenuBar> }} key={"/lp-article"} />

            {/* Agent Login Log */}
            <PrivateRoute exact path="/agent-login-logs" component={function () { return <MenuBar title="Agent Login Logs"><LogAgentLogin /></MenuBar> }} key={"/agent-login-logs"} />

            {/* Agent Login Log */}
            <PrivateRoute exact path="/user-login-logs" component={function () { return <MenuBar title="User Login Logs"><LogUserLogin /></MenuBar> }} key={"/user-login-logs"} />

            {/* City */}
            <PrivateRoute exact path="/city" component={function () { return <MenuBar title="City List"><CityList /></MenuBar> }} key={"/city"} />
            <PrivateRoute exact path="/city/add" component={function () { return <MenuBar title="City Form"><CityForm /></MenuBar> }} key={"/city/add"} />
            <PrivateRoute exact path="/city/edit/:id" component={function () { return <MenuBar title="City Form"><CityForm /></MenuBar> }} key={"/city/edit"} />

            {/* Sub Project */}
            <PrivateRoute exact path="/subproject" component={function () { return <MenuBar title="Sub Project List"><SubProjectList /></MenuBar> }} key={"/subproject"} />
            <PrivateRoute exact path="/subproject/add" component={function () { return <MenuBar title="Sub Project Form"><SubProjectForm /></MenuBar> }} key={"/subproject/add"} />
            <PrivateRoute exact path="/subproject/edit/:id" component={function () { return <MenuBar title="Sub Project Form"><SubProjectForm /></MenuBar> }} key={"/subproject/edit"} />

            {/* KPR Log */}
            <PrivateRoute exact path="/kpr-logs-old" component={function () { return <MenuBar title="KPR Logs"><LogKPROld /></MenuBar> }} key={"/kpr-logs-old"} />
            <PrivateRoute exact path="/kpr-logs" component={function () { return <MenuBar title="KPR Logs"><LogKPR /></MenuBar> }} key={"/kpr-logs"} />

            {/* Account */}
            <PrivateRoute exact path="/account" component={function () { return <MenuBar title="Account List"><AccountList /></MenuBar> }} key={"/account"} />
            <PrivateRoute exact path="/account/:account_id" component={function () { return <MenuBar title="Account Detail"><AccountList /></MenuBar> }} key={"/account"} />

            {/* BlackListed Phone */}
            <PrivateRoute exact path="/blacklisted-phone" component={function () { return <MenuBar title="Blacklisted Phone"><BlacklistSystemList /></MenuBar> }} key={"/blacklisted-phone"} />

            {/* Page Tracking */}
            <PrivateRoute exact path="/page-tracking" component={function () { return <MenuBar title="Page Tracking"><PageTracking /></MenuBar> }} key={"/page-tracking"} />

            {/* Account Tracking */}
            <PrivateRoute exact path="/account-tracking" component={function () { return <MenuBar title="Account Tracking"><AccountTracker /></MenuBar> }} key={"/account-tracking"} />

            {/* Wishlist Export  */}
            <PrivateRoute exact path="/wishlist-export" component={function () { return <MenuBar title="Wishlist Export"><WishlistExport /></MenuBar> }} key={"/account-tracking"} />

            {/* Pilar Project  */}
            <PrivateRoute exact path="/pilar-project" component={function () { return <MenuBar title="Pilar Project"><PilarProjectList /></MenuBar> }} />

            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </SnackbarProvider>
    </PermissionProvider>
  )
}

export default App;
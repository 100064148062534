import React, { useState } from 'react';
import { Button, Theme, Tooltip, IconButton, Switch, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Swal from 'sweetalert2';

// Components
import LoadingScreen from '../../_components/LoadingScreen';
import DataTable from '../../_components/_dataTable/DataTable';
import { Link as RouterLink, useHistory } from "react-router-dom";

// Utils
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, openLink, renderWarningButton } from '../../_utils/Helper';

// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { usePermission } from '../../_providers/PermissionProvider';

interface Props {
}

export interface IRow {
    id: number;
    kpr_listing_id: null | string;
    category: string;
    project_id: number;
    cluster_id: number;
    is_published: number;
    title: string;
    slug: string;
    content: string;
    content_short: string;
    promotion_start_date: Date | null;
    promotion_end_date: Date | null;
    meta_title: null | string;
    meta_description: null | string;
    meta_keyword: null | string;
    image: null | string;
    image_thumbnail: null | string;
    image_link_url: null | string;
    image_caption: null | string;
    image_alt: null | string;
    banner_image: null | string;
    banner_image_small: null | string;
    banner_url: null | string;
    banner_caption: null | string;
    banner_alt: null | string;
    listings: null;
    created_at: Date;
    updated_at: Date;
    project_name: string;
    cluster_name: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginBottom: theme.spacing(3)
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        addButton: {
            marginBottom: '20px'
        },
        importButton: {
            float: 'right'
        },
        errorContainer: {
            maxHeight: '200px',
            overflowY: 'auto'
        }
    })
);

export const ARTICLE_CATEGORIES = [
    // { label: 'All Categories', value: '', },
    // { label: 'What’s on Sinarmas Land', value: 'whatsnew', },
    { label: 'Promotion', value: 'promotion', },
    { label: 'Residential', value: 'residential', },
    { label: 'Kavling Lot', value: 'kavling', },
    { label: 'Apartment', value: 'apartment', },
    { label: 'Shophouse', value: 'shophouse', },
    { label: 'Business Loft', value: 'businessloft', },
    { label: 'Kiosk', value: 'kiosk', },
    { label: 'Berita Terkini', value: 'berita-terkini', },
    { label: 'Teknologi', value: 'teknologi', },
    { label: 'Gaya Hidup & Hobi', value: 'gaya-hidup-hobi', },
    { label: 'Keuangan & Investasi', value: 'keuangan-investasi', },
    { label: 'Info KPR', value: 'info-kpr', },
    { label: 'Tips Properti', value: 'tips-properti', },
    { label: 'Desain', value: 'desain', },
    { label: 'Inspirasi Hunian', value: 'inspirasi-hunian', },
]

const ArticleList = (props: Props) => {
    const permissions = usePermission()
    const API_URL = process.env.REACT_APP_API_URL + '/blog';
    const classes = useStyles();
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false);
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    const columns = [
        {
            name: 'title',
            label: 'Title',
            type: 'string',
            filterable: true
        },
        {
            name: 'category',
            label: 'Category',
            type: 'string',
            filterable: true,
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                ...ARTICLE_CATEGORIES.map(category => ({
                    key: category.value,
                    value: category.label,
                }))
            ],
            render: (data: any) => ARTICLE_CATEGORIES.filter(category => category.value === data)[0]?.label || '-'
        },
        {
            name: 'project_name',
            label: 'Project',
            type: 'string',
            filterable: true,
            render: (data: any) => data || '-',
        },
        {
            name: 'cluster_name',
            label: 'Cluster',
            type: 'string',
            filterable: true,
            render: (data: any) => data || '-',
        },
        {
            name: 'is_published',
            label: 'Published',
            type: 'string',
            defaultOption: '',
            options: [
                {
                    key: '',
                    value: 'All'
                },
                {
                    key: '1',
                    value: 'Published'
                },
                {
                    key: '0',
                    value: 'Not Published'
                }
            ],
            filterable: true,
            render: (data: any, row: any, columnName: string, options: any[], setRows: Function, rows: any) => {
                return <Switch
                    checked={+data === 1}
                    onChange={() => switchActive(row.id)}
                    color="secondary"
                />
            }
        },
        {
            name: 'EXTRA',
            label: 'Action',
            type: 'string',
            filterable: false,
            sortable: false,
            render: (row: any) => {
                return (
                    <div className={classes.actionContainer}>
                        <Tooltip title="Edit">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handleEdit(row.id)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Preview">
                            <IconButton
                                color="primary"
                                component="span"
                                onClick={() => handlePreview(row.id)}
                            >
                                <SearchIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {
                            row.is_published === 1 &&
                            <Tooltip title="Open Page">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={() => handleOpenPage(row)}
                                >
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                );
            }
        },
    ];

    const switchActive = (id: string) => {
        renderWarningButton("Apakah anda yakin ingin mengganti published?")
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios
                        .post(`${API_URL}/toggle-published`, { id })
                        .then(() => {
                            Swal.fire({
                                title: "Berhasil mengganti status",
                                icon: 'success',
                                onAfterClose: () => {
                                    setReDrawDatatable(new Date().getTime())
                                },
                                timer: 1000
                            })
                        })
                        .catch(error => {
                            generalErrorHandler(error)
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleEdit = (id: string) => {
        history.push(`article/edit/${id}`)
    }

    const handlePreview = (id: string) => {
        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/generate-preview-redis`, {
            id,
            type: 'blog',
        })
            .then(res => res.data)
            .then(data => {
                openLink(`${process.env.REACT_APP_MAIN_URL}/preview/article/${id}`)
                setIsLoading(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    const handleOpenPage = (row: IRow) => {
        openLink(`${process.env.REACT_APP_MAIN_URL}/article/${row.category}/${row.slug}`)
    }

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />

            <Grid container justifyContent="space-between" className={classes.buttonContainer}>
                <Grid item>
                    {
                        permissions['blog.manage'] &&
                        <RouterLink
                            to="/article/add"
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.addButton}
                                startIcon={<AddIcon />}
                            >
                                Add Article
                            </Button>
                        </RouterLink>
                    }
                </Grid>
            </Grid>

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
        </>
    );
}

export default ArticleList;